<template>
  <v-container fluid>
    <v-row dense justify="end">
      <v-col cols="12" sm="6" md="2">
        <v-select
          hide-height="5"
          v-model="filterByStatus"
          :items="filterByItems"
          :placeholder="$_strings.masterShipper.statusDriver"
          outlined
          hide-details
          class="caption"
          dense
          @change="filterStatus"
        >
        </v-select>
      </v-col>
      <v-col cols="12" sm="6" md="3">
        <v-text-field
          dense
          hide-details
          outlined
          class="caption"
          :placeholder="searchPlaceholder"
          ref="search"
          v-model="searchText"
          @keyup.enter="search"
        >
          <template v-slot:prepend-inner>
            <v-icon @click="search"> mdi-magnify </v-icon>
          </template>
        </v-text-field>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <v-data-table
          calculate-widths
          item-key="id"
          :loading="isLoading"
          :headers="showHeaders"
          :items="items"
          :server-items-length="totalItems"
          :options.sync="pagination"
          :footer-props="{showCurrentPage: true, showFirstLastPage: true, 'items-per-page-options': $_item_per_page}"
          @click:row="rowClicked"
        >
          <template v-slot:[`item.mouNo`]="{item: {mouNo}}">
            <p>{{mouNo || '-'}}</p>
          </template>
          <template v-slot:[`item.contractNo`]="{item: {contractNo}}">
            <p>{{contractNo || '-'}}</p>
          </template>
          <template v-slot:[`item.companyAddress`]="{item: {companyAddress}}">
            <p>{{companyAddress || '-'}}</p>
          </template>
          <template v-slot:[`item.mouPeriodMonths`]="{item: {mouPeriodMonths, mouPeriodStart, mouPeriodEnd}}">
            <p class="font-weight-bold mb-0">
              {{mouPeriodMonths && `${mouPeriodMonths} Bulan` || '-'}}
            </p>
            <p class="mt-0">
              {{(mouPeriodStart && mouPeriodEnd)
                &&
                `${dateFormat(mouPeriodStart)} s/d ${dateFormat(mouPeriodEnd)}`
              }}
            </p>
          </template>
          <template v-slot:[`item.monthsExpired`]="{item: {monthsExpired}}">
            <p class="mt-0">
              {{monthsExpired && `${monthsExpired} Bulan` || '-'}}
            </p>
          </template>
          <template v-slot:[`item.createdAt`]="{item: {createdAt}}">
            <p>{{createdAt || '-'}}</p>
          </template>
          <template v-slot:[`item.createdBy`]="{item: {createdBy}}">
            <p>{{createdBy || '-'}}</p>
          </template>
          <template v-slot:[`item.updatedBy`]="{item: {updatedBy}}">
            <p>{{updatedBy || '-'}}</p>
          </template>
          <template v-slot:[`item.status`]="{item: {status}}">
            <p>{{status.toLowerCase() === 'active' ? 'Aktif' : 'Tidak Aktif'}}</p>
          </template>
          <template v-slot:[`item.actions`]="{item}">
            <v-tooltip bottom v-if="userAccess.canView">
              <template v-slot:activator="{ on, attrs }">
                <v-btn v-bind="attrs" v-on="on" icon color="primary" @click="routeNavigateDetail(item)" @click.stop.prevent>
                  <v-icon small>mdi-magnify</v-icon>
                </v-btn>
              </template>
              <span>Lihat Detail</span>
            </v-tooltip>
            <v-tooltip top v-if="userAccess.canUpdate">
              <template v-slot:activator="{ on, attrs }">
                <v-btn v-bind="attrs" v-on="on" icon color="primary" @click="routeNavigateEdit(item)" @click.stop.prevent>
                  <v-icon small>mdi-pencil</v-icon>
                </v-btn>
              </template>
              <span>Ubah</span>
            </v-tooltip>
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  v-bind="attrs"
                  v-on="on"
                  color="primary"
                  small
                  icon
                  @click="() => showDialogListContact(item)"
                  @click.stop.prevent
                >
                  <v-icon small>mdi-account-box</v-icon>
                </v-btn>
              </template>
              <span>Lihat Kontak</span>
            </v-tooltip>
          </template>
          <template v-slot:[`footer.page-text`]="props">
            <span>
              {{$_strings.masterShipper.pageName}}
              <span v-if="items.length">
                {{props.pageStart}}-{{props.pageStop}} of {{props.itemsLength}}
              </span>
            </span>
          </template>
        </v-data-table>
      </v-col>
    </v-row>
    <DialogListHistory
      ref="dialogListHistory"
    />
  </v-container>
</template>

<script>
import {
  dateFormat,
  skipEmptyStringObject,
  handleSortBy,
  handlerPagination,
  defaultPagination,
} from '@/helper/commonHelpers';
import DialogListHistory from './Dialog/CustomerHistory';

export default {
  name: 'customer',
  components: {
    DialogListHistory,
  },
  data() {
    return {
      isLoading: false,
      searchText: this.$route.query.search || '',
      filterByStatus: this.$route.query.status || '',
      items: [],
      histories: [],
      headers: [
        {
          text: 'Nomor MoU',
          value: 'mouNo',
          class: 'white--text primary text-capitalize',
          width: '200px',
          cellClass: 'clickable',
        },
        {
          text: this.$_strings.masterShipper.contractNo,
          value: 'contractNo',
          class: 'white--text primary text-capitalize',
          width: '200px',
          cellClass: 'clickable',
        },
        {
          text: this.$_strings.masterShipper.companyName,
          value: 'companyName',
          class: 'white--text primary text-capitalize',
          width: '220px',
          cellClass: 'clickable',
        },
        {
          text: this.$_strings.masterShipper.abbreviation,
          value: 'abbreviation',
          class: 'white--text primary text-capitalize',
          width: '120px',
          cellClass: 'clickable',
        },
        {
          text: this.$_strings.masterShipper.companyAddress,
          value: 'companyAddress',
          class: 'white--text primary text-capitalize',
          width: '200px',
          cellClass: 'clickable',
        },
        {
          text: this.$_strings.masterShipper.mouPeriodMonths,
          value: 'mouPeriodMonths',
          class: 'white--text primary text-capitalize',
          width: '250px',
          cellClass: 'clickable',
        },
        {
          text: this.$_strings.mou.MOU_EXPIRED_TABLE_HEADER_LABEL,
          class: 'white--text primary',
          value: 'monthsExpired',
          width: '18vw',
        },
        {
          text: this.$_strings.masterShipper.createdBy,
          value: 'createdBy',
          class: 'white--text primary text-capitalize',
          width: '200px',
          cellClass: 'clickable',

        },
        {
          text: this.$_strings.masterShipper.updatedBy,
          value: 'updatedBy',
          class: 'white--text primary text-capitalize',
          width: '200px',
          cellClass: 'clickable',
        },
        {
          text: this.$_strings.masterShipper.status,
          value: 'status',
          class: 'white--text primary text-capitalize',
          width: '200px',
          cellClass: 'clickable',
          // sortable: false,
        },
        {
          text: 'Aksi',
          value: 'actions',
          class: 'white--text primary text-capitalize',
          width: '150px',
          sortable: false,
        },
      ],
      filterByItems: [
        {
          text: 'Semua Status',
          value: '',
        },
        {
          text: 'Aktif',
          value: 'active',
        },
        {
          text: 'Tidak Aktif',
          value: 'nonactive',
        },
      ],
      pagination: this.defaultPagination(),
      totalItems: 0,
      dialogListHistory: false,
    };
  },
  watch: {
    pagination: {
      handler(newVal) {
        this.handlerPagination(this, newVal);
        if (!this.isLoading) this.fetchList();
      },
      deep: true,
    },
    '$route.path': async function fetchList() {
      this.items = [];
      await this.fetchList('', '');
      this.searchText = '';
      this.filterByStatus = '';
      this.$router.replace({
        query: {
          page: 1,
          itemsPerPage: 10,
        },
      });
    },
  },
  computed: {
    showHeaders() {
      return this.headers.map((header) => {
        if (!this.items.length) {
          return {
            ...header,
            width: 'auto',
          };
        }
        return {
          ...header,
        };
      });
    },
    searchPlaceholder() {
      const [,, path] = this.$route.path.split('/');
      if (path === 'customer') return this.$_strings.masterShipper.searchText;
      return this.$_strings.masterPatner.searchText;
    },
  },
  methods: {
    dateFormat,
    skipEmptyStringObject,
    handleSortBy,
    handlerPagination,
    defaultPagination,
    toggleLoading(boolean) {
      if (this.items.length > 0) {
        this.isLoading = false;
        return boolean ? this.$root.$loading.show() : this.$root.$loading.hide();
      }
      this.$root.$loading.hide();
      this.isLoading = boolean;
    },
    rowClicked(e, { item }) {
      this.$router.push(`detail/${item.id}/${item.mouId || ''}`);
    },
    routeNavigateDetail(item) {
      this.$router.push(`detail/${item.id}/${item.mouId || ''}`);
    },
    routeNavigateEdit(item) {
      this.$router.push(`edit/${item.id}/${item.mouId || ''}`);
    },
    setFilter() {
      if (this.pagination.page > 1) {
        this.pagination.page = 1;
        return;
      }
      this.fetchList();
    },
    search() {
      if (this.$route.query.search !== this.searchText) {
        this.$router.replace({
          query: {
            ...this.$route.query,
            search: this.searchText,
          },
        });
      }
      this.setFilter();
    },
    filterStatus() {
      if (this.$route.query.status !== this.filterByStatus) {
        this.$router.replace({
          query: {
            ...this.$route.query,
            status: this.filterByStatus,
          },
        });
      }
      this.setFilter();
    },
    getServices(service) {
      // Services => list, history
      const [,, path] = this.$route.path.split('/');
      if (service === 'list') {
        if (path === 'customer') return this.$_services.masterShipper.getListMasterShipper;
        if (path === 'rekanan') return this.$_services.masterPatner.getListMasterPatner;
      }
      return null;
    },
    async fetchList(search = this.$route.query.search || '', status = this.$route.query.status || '') {
      const {
        page, itemsPerPage, sortBy, sortDesc,
      } = this.pagination;
      const filters = {
        page: page - 1,
        size: itemsPerPage,
        companyName: search || '',
        sort: this.handleSortBy({ sortBy, sortDesc }),
        status: status || '',
      };
      try {
        this.toggleLoading(true);
        const result = await this.getServices('list')(this.skipEmptyStringObject(filters));
        this.items = result.data.contents;
        this.totalItems = result.data.totalData;
      } finally {
        this.toggleLoading(false);
      }
    },
    showDialogListContact(item) {
      this.$refs.dialogListHistory.id = item.id;
      this.$refs.dialogListHistory.dialog = true;
    },
  },
};
</script>
