var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{attrs:{"fluid":""}},[_c('v-row',{attrs:{"dense":"","justify":"end"}},[_c('v-col',{attrs:{"cols":"12","sm":"6","md":"2"}},[_c('v-select',{staticClass:"caption",attrs:{"hide-height":"5","items":_vm.filterByItems,"placeholder":_vm.$_strings.masterShipper.statusDriver,"outlined":"","hide-details":"","dense":""},on:{"change":_vm.filterStatus},model:{value:(_vm.filterByStatus),callback:function ($$v) {_vm.filterByStatus=$$v},expression:"filterByStatus"}})],1),_c('v-col',{attrs:{"cols":"12","sm":"6","md":"3"}},[_c('v-text-field',{ref:"search",staticClass:"caption",attrs:{"dense":"","hide-details":"","outlined":"","placeholder":_vm.searchPlaceholder},on:{"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return _vm.search($event)}},scopedSlots:_vm._u([{key:"prepend-inner",fn:function(){return [_c('v-icon',{on:{"click":_vm.search}},[_vm._v(" mdi-magnify ")])]},proxy:true}]),model:{value:(_vm.searchText),callback:function ($$v) {_vm.searchText=$$v},expression:"searchText"}})],1)],1),_c('v-row',[_c('v-col',[_c('v-data-table',{attrs:{"calculate-widths":"","item-key":"id","loading":_vm.isLoading,"headers":_vm.showHeaders,"items":_vm.items,"server-items-length":_vm.totalItems,"options":_vm.pagination,"footer-props":{showCurrentPage: true, showFirstLastPage: true, 'items-per-page-options': _vm.$_item_per_page}},on:{"update:options":function($event){_vm.pagination=$event},"click:row":_vm.rowClicked},scopedSlots:_vm._u([{key:"item.mouNo",fn:function(ref){
var mouNo = ref.item.mouNo;
return [_c('p',[_vm._v(_vm._s(mouNo || '-'))])]}},{key:"item.contractNo",fn:function(ref){
var contractNo = ref.item.contractNo;
return [_c('p',[_vm._v(_vm._s(contractNo || '-'))])]}},{key:"item.companyAddress",fn:function(ref){
var companyAddress = ref.item.companyAddress;
return [_c('p',[_vm._v(_vm._s(companyAddress || '-'))])]}},{key:"item.mouPeriodMonths",fn:function(ref){
var ref_item = ref.item;
var mouPeriodMonths = ref_item.mouPeriodMonths;
var mouPeriodStart = ref_item.mouPeriodStart;
var mouPeriodEnd = ref_item.mouPeriodEnd;
return [_c('p',{staticClass:"font-weight-bold mb-0"},[_vm._v(" "+_vm._s(mouPeriodMonths && (mouPeriodMonths + " Bulan") || '-')+" ")]),_c('p',{staticClass:"mt-0"},[_vm._v(" "+_vm._s((mouPeriodStart && mouPeriodEnd) && ((_vm.dateFormat(mouPeriodStart)) + " s/d " + (_vm.dateFormat(mouPeriodEnd))))+" ")])]}},{key:"item.monthsExpired",fn:function(ref){
var monthsExpired = ref.item.monthsExpired;
return [_c('p',{staticClass:"mt-0"},[_vm._v(" "+_vm._s(monthsExpired && (monthsExpired + " Bulan") || '-')+" ")])]}},{key:"item.createdAt",fn:function(ref){
var createdAt = ref.item.createdAt;
return [_c('p',[_vm._v(_vm._s(createdAt || '-'))])]}},{key:"item.createdBy",fn:function(ref){
var createdBy = ref.item.createdBy;
return [_c('p',[_vm._v(_vm._s(createdBy || '-'))])]}},{key:"item.updatedBy",fn:function(ref){
var updatedBy = ref.item.updatedBy;
return [_c('p',[_vm._v(_vm._s(updatedBy || '-'))])]}},{key:"item.status",fn:function(ref){
var status = ref.item.status;
return [_c('p',[_vm._v(_vm._s(status.toLowerCase() === 'active' ? 'Aktif' : 'Tidak Aktif'))])]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [(_vm.userAccess.canView)?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":"","color":"primary"},on:{"click":[function($event){return _vm.routeNavigateDetail(item)},function($event){$event.stopPropagation();$event.preventDefault();}]}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"small":""}},[_vm._v("mdi-magnify")])],1)]}}],null,true)},[_c('span',[_vm._v("Lihat Detail")])]):_vm._e(),(_vm.userAccess.canUpdate)?_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":"","color":"primary"},on:{"click":[function($event){return _vm.routeNavigateEdit(item)},function($event){$event.stopPropagation();$event.preventDefault();}]}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"small":""}},[_vm._v("mdi-pencil")])],1)]}}],null,true)},[_c('span',[_vm._v("Ubah")])]):_vm._e(),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"color":"primary","small":"","icon":""},on:{"click":[function () { return _vm.showDialogListContact(item); },function($event){$event.stopPropagation();$event.preventDefault();}]}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"small":""}},[_vm._v("mdi-account-box")])],1)]}}],null,true)},[_c('span',[_vm._v("Lihat Kontak")])])]}},{key:"footer.page-text",fn:function(props){return [_c('span',[_vm._v(" "+_vm._s(_vm.$_strings.masterShipper.pageName)+" "),(_vm.items.length)?_c('span',[_vm._v(" "+_vm._s(props.pageStart)+"-"+_vm._s(props.pageStop)+" of "+_vm._s(props.itemsLength)+" ")]):_vm._e()])]}}],null,true)})],1)],1),_c('DialogListHistory',{ref:"dialogListHistory"})],1)}
var staticRenderFns = []

export { render, staticRenderFns }