<template>
  <v-dialog
    v-model="dialog"
    persistent
    scrollable
    max-width="800"
  >
    <v-card>
      <v-card-title class="caption">
        <v-row>
          <v-col class="d-flex pa-0 ma-0 justify-end">
            <v-toolbar-items>
              <v-icon @click="dialog = false">mdi-close</v-icon>
            </v-toolbar-items>
          </v-col>
        </v-row>
      </v-card-title>
      <v-card-title class="justify-center">
        <h4>{{ $_strings.masterShipper.headerTitleDialog }}</h4>
      </v-card-title>
      <v-card-text>
        <v-data-table
          :loading="isLoading"
          loading-text="Sedang memuat ..."
          :headers="displayedHeader"
          :items="items"
          item-key="id"
          hide-default-footer
          :items-per-page="100"
          class="elevation-1"
        >
          <template v-slot:[`item.activated`]="{item : {activated}}">
            {{ activated ? 'Aktif' : 'Tidak Aktif' }}
          </template>
        </v-data-table>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>

export default {
  computed: {
    displayedHeader() {
      return this.headers.map((i) => ({
        ...i,
        class: 'white--text primary',
      }));
    },
  },
  watch: {
    dialog(newVal) {
      if (newVal && this.id && !this.items.length) {
        this.fetchHistory();
        return;
      }
      this.items = [];
      this.id = null;
    },
  },
  data() {
    return {
      id: null,
      dialog: false,
      isLoading: false,
      items: [],
      headers: [
        { text: this.$_strings.masterShipper.headerCustomer.NAMA, value: 'username', sortable: true },
        { text: this.$_strings.masterShipper.headerCustomer.EMAIL, value: 'email', sortable: true },
        { text: this.$_strings.masterShipper.headerCustomer.ROLE, value: 'roleRolesName', sortable: true },
        { text: this.$_strings.masterShipper.headerCustomer.STATUS, value: 'activated', sortable: true },
      ],
    };
  },
  methods: {
    async fetchHistory() {
      try {
        this.isLoading = true;
        const result = await this.$_services.masterShipper.fetchCustomerHistory(this.id);
        this.items = result.data.contents;
      } finally {
        this.isLoading = false;
      }
    },
  },
};
</script>
